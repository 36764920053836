import { Navigate, RouteObject } from "react-router-dom"
import LayoutPlain from "app/components/Layout/Plain"
import NotFound from "app/pages/NotFound"
import Error from "app/pages/Error"
import NewCarSuccess from "app/pages/NewCarSuccess"
import NewCarForm from "app/pages/newCarForm"
import NewCarStep1 from "app/pages/newCarForm/Step1"
import NewCarStep2 from "app/pages/newCarForm/Step2"
import NewCarStep3 from "app/pages/newCarForm/Step3"
import NewCarStep4 from "app/pages/newCarForm/Step4"
import NewCarStep5 from "app/pages/newCarForm/Step5"
import PreownedSuccess from "app/pages/PreOwnedSuccess"
import { FormSteps } from "app/enums/formSteps.enum"
import PreownedCarFormPage from "app/pages/PreOwnedCarForm"
import LayoutPreOwned from "app/components/Layout/PreOwned"

interface Routes extends RouteObject {
  layout?: React.FC
}

const routes: Routes[] = [
  {
    path: "/",
    element: <Navigate to="/1" replace />
  },
  {
    path: "/error",
    element: <Error />
  },
  {
    path: "/success",
    element: <NewCarSuccess />
  },
  {
    path: "/preowned",
    element: <PreownedCarFormPage />,
    layout: LayoutPreOwned
  },
  {
    path: "/preowned/success",
    element: <PreownedSuccess />,
    layout: LayoutPreOwned
  },
  {
    path: "/:step",
    element: <NewCarForm />
  },
  {
    path: "*",
    element: <NotFound />,
    layout: LayoutPlain
  }
]

export const STEPS: { [key: number]: React.ElementType } = {
  [FormSteps.USER_INFO_STEP]: NewCarStep1,
  [FormSteps.COMPANY_INFO_STEP]: NewCarStep2,
  [FormSteps.INVESTMENT_STEP]: NewCarStep3,
  [FormSteps.DESIRED_FINANCING_STEP]: NewCarStep4,
  [FormSteps.VIEW_PROPOSALS_STEP]: NewCarStep5
}

export default routes

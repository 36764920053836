import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  createLeadRequest,
  updateLeadRequest
} from "app/api"
import { AxiosError } from "axios"
import { RootState } from "state/store"
import { RequestStatus } from "state/types"
import {
  PreOwnedFormState,
  PreOwnedFormStateData,
  LeadResponse,
  LeadResponseData,
  TrackLeadRequestBody
} from "./types"

const initialState: PreOwnedFormState = {
  data: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    language: undefined,
    enterpriseNumber: "",
    enterpriseName: "",
    carModel: ""
  },
  leadResponse: {
    data: {},
    status: RequestStatus.IDLE,
    errors: []
  }
}

interface FormStateError {
  state: RootState
  rejectValue: {
    errors: string[]
  }
}

export const trackLeadThunk = createAsyncThunk<
  LeadResponseData,
  TrackLeadRequestBody,
  FormStateError
>("form/trackLead", async (payload, thunkAPI) => {
  try {
    const { data: responseData } = await createLeadRequest(payload)
    return responseData as LeadResponseData
  } catch (err) {
    const error = err as AxiosError<{ message: string[] }>
    const errors = error.response?.data.message || []

    return thunkAPI.rejectWithValue({
      errors
    })
  }
})

export const preownedFormSlice = createSlice({
  name: "preownedForm",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<PreOwnedFormStateData>) => {
      const { payload } = action

      for (const property in payload) {
        const value = payload[property]
        state.data[property] = value
      }
    },
    setLeadResponse: (state, action: PayloadAction<LeadResponse>) => {
      state.leadResponse = action.payload
    },
    reset: (state, _action: PayloadAction<void>) => {
      state.data = initialState.data
      state.leadResponse = initialState.leadResponse
    }
  },
  extraReducers: builder => {
    builder.addCase(trackLeadThunk.pending, state => {
      state.leadResponse.status = RequestStatus.PENDING
    })
    builder.addCase(trackLeadThunk.fulfilled, (state, action) => {
      state.leadResponse.status = RequestStatus.FULFILLED
      state.leadResponse.data = action.payload
      state.leadResponse.errors = []
      if (!action.payload.verifiedAt) {
        state.data.verificationCode = undefined
      }
    })
    builder.addCase(trackLeadThunk.rejected, (state, action) => {
      state.leadResponse.status = RequestStatus.REJECTED
      state.leadResponse.data = initialState.leadResponse.data

      if (action.payload) {
        state.leadResponse.errors = action.payload.errors
      } else {
        state.leadResponse.errors = action.error.message
          ? [action.error.message]
          : []
      }
    })
  }
})

export const { setData, setLeadResponse } = preownedFormSlice.actions

export default preownedFormSlice.reducer

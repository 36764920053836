import Description from "app/components/Description"
import Heading from "app/components/Heading"
import Overview from "app/components/Overview"
import {
  StyledOverViewAmount,
  StyledOverViewCaption,
  StyledOverViewHeading,
  StyledOverviewHeaderTitleWrapper,
  StyledOverViewHeaderInfoWrapper
} from "app/components/Overview/style"
import Space from "app/components/Space"
import { useTranslate } from "hooks/translate"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import {
  getCarModel,
  getSimulateProposalsResponse,
  getSimulateProposalsResponseStatus
} from "state/form/selectors"
import {
  getEnvVariable,
  parseEuroSignPlacement,
  parseNumberStringToLocale
} from "utils"
import SpecialOffer from "app/components/SpecialOffer"
import { getAppLocale } from "state/app/selectors"
import { Locales } from "i18n"
import { CarModels } from "app/enums/carModels.enum"

const SPECIAL_OFFER_ACTIVE =
  getEnvVariable("REACT_APP_SPECIAL_OFFER_ACTIVE") === "true"

const productLinks: {
  [key in Locales]: {
    [key: string]: string
  }
} = {
  [Locales.NL]: {
    "1096":
      "https://multimediafiles.kbcgroup.eu/ng/published/LEASE/PDF/LEASE_productfiche_Leasing_of_Renting_voertuig.pdf",
    "3403":
      "https://multimediafiles.kbcgroup.eu/ng/published/LEASE/PDF/LEASE_productfiche_Leasing_of_Renting_voertuig.pdf",
    "0430":
      "https://multimediafiles.kbcgroup.eu/ng/PUBLISHED/KBC/PDF/RUN/KREDIETEN/kbc-investeringskrediet-productfiche.pdf"
  },
  [Locales.FR]: {
    "1096":
      "https://multimediafiles.kbcgroup.eu/ng/PUBLISHED/KBC/PDF/Leasing_financier_et_renting_KBC.pdf",
    "3403":
      "https://multimediafiles.kbcgroup.eu/ng/PUBLISHED/KBC/PDF/Leasing_financier_et_renting_KBC.pdf",
    "0430":
      "https://multimediafiles.kbcgroup.eu/ng/PUBLISHED/KBC/PDF/RUN/KREDIETEN/kbc-credit-d-investissement-fiche-produit.pdf"
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const showSpecialOffer = (specialOfferActive: boolean, carModel: string) => {
  return true
}

const Step5: React.FC = () => {
  const { t } = useTranslate()
  const locale = useSelector(getAppLocale)
  const carModel = useSelector(getCarModel)
  const { products = [] } = useSelector(getSimulateProposalsResponse)
  const { isRejected } = useSelector(getSimulateProposalsResponseStatus)

  if (isRejected) {
    return <Navigate to="/error" replace />
  }

  return (
    <>
      {showSpecialOffer(SPECIAL_OFFER_ACTIVE, carModel) && <SpecialOffer />}
      <Heading>{t("form.step_5.heading")}</Heading>
      <Space bottom={3}>
        <Description>{t("form.step_5.body")}</Description>
      </Space>
      {products.map(product => {
        return (
          <Overview key={product.productTypeNumber}>
            <Overview.Header>
              <StyledOverviewHeaderTitleWrapper>
                <StyledOverViewHeading forwardedAs="h2">
                  {product.productTypeDescription}
                </StyledOverViewHeading>
                <a
                  href={productLinks[locale][product.productTypeNumber]}
                  target="_blank"
                >
                  {t("system.more_info")}
                </a>
              </StyledOverviewHeaderTitleWrapper>
              <StyledOverViewHeaderInfoWrapper>
                <StyledOverViewAmount>
                  {parseEuroSignPlacement(
                    locale,
                    parseNumberStringToLocale(product.termAmount, 2)
                  )}
                </StyledOverViewAmount>
                <StyledOverViewCaption>
                  {t("system.payment.monthly")}
                </StyledOverViewCaption>
              </StyledOverViewHeaderInfoWrapper>
            </Overview.Header>
            <Overview.Footer>
              <dl>
                {product.externalRate && (
                  <>
                    <dt>{t("system.external_rate")}</dt>
                    <dd>{parseNumberStringToLocale(product.externalRate)} %</dd>
                  </>
                )}
                {product.ownContributionAmount && (
                  <>
                    <dt>{t("system.own_contribution")}</dt>
                    <dd>
                      {parseEuroSignPlacement(
                        locale,
                        parseNumberStringToLocale(product.ownContributionAmount)
                      )}
                    </dd>
                  </>
                )}
                {product.oneOffFeeDossierAmount && (
                  <>
                    <dt>{t("system.dossier_amount")}</dt>
                    <dd>
                      {parseEuroSignPlacement(
                        locale,
                        parseNumberStringToLocale(
                          product.oneOffFeeDossierAmount
                        )
                      )}
                    </dd>
                  </>
                )}
                {product.totalRepaymentAmount && (
                  <>
                    <dt>{t("system.total_repayment_amount")}</dt>
                    <dd>
                      {parseEuroSignPlacement(
                        locale,
                        parseNumberStringToLocale(product.totalRepaymentAmount)
                      )}
                    </dd>
                  </>
                )}
              </dl>
            </Overview.Footer>
          </Overview>
        )
      })}
    </>
  )
}

export default Step5
